import React, { useEffect, useState } from 'react';
import { ConnectButton, useWallet } from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';
import axios from 'axios';
import './App.css';
import Confetti from 'react-confetti';
import { getFullnodeUrl } from '@mysten/sui.js/client';
import { TransactionBlock } from '@mysten/sui.js/transactions';

const houseAddress = '0x7466c6cd211d6e9d24e3042b34648062d351926cd043934ba9ee7e14ff54d86b';
const serverUrl = process.env.REACT_APP_SERVER_URL;

const App = () => {
  const { connected, account } = useWallet();
  const wallet = useWallet();
  const [userBalance, setUserBalance] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const [snakeBet, setsnakeBet] = useState('');
  const [betEighty, setBetEighty] = useState('');
  const [betSeventy, setBetSeventy] = useState('');
  const [betSixty, setBetSixty] = useState('');
  const [betFifty, setBetFifty] = useState('');
  const [betEightyAmount, setBetEightyAmount] = useState('');
  const [betSeventyAmount, setBetSeventyAmount] = useState('');
  const [betSixtyAmount, setBetSixtyAmount] = useState('');
  const [betFiftyAmount, setBetFiftyAmount] = useState('');
  const [results, setResults] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [snakeHit, setsnakeHit] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/transactions`);
        setTransactions(response.data);
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  const fetchBalance = async (address) => {
    try {
      const response = await fetch(getFullnodeUrl('mainnet'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'suix_getBalance',
          params: [address],
        }),
      });
      const data = await response.json();

      if (data.result && data.result.totalBalance) {
        const balance = data.result.totalBalance;
        setUserBalance(balance / 10 ** 9); // Convert balance to SUI if needed
      } else {
        console.error('Failed to fetch balance: No result found in response');
        setUserBalance(0);
      }
    } catch (error) {
      console.error('Failed to fetch balance:', error);
      setUserBalance(0);
    }
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        if (connected && wallet.account) {
          setWalletAddress(wallet.account.address);
          await fetchBalance(wallet.account.address);
        } else {
          console.error('Account or address is null');
        }
      } catch (error) {
        console.error('Failed to fetch wallet info:', error);
      }
    };

    fetchWalletInfo();
  }, [connected, account]);

  const handleConnectError = () => {
    alert('Failed to connect wallet. Please try again.');
  };

  const handleFlip = async () => {
    const totalBet = Number(snakeBet || 0) + Number(betEightyAmount || 0) + Number(betSeventyAmount || 0) + Number(betSixtyAmount || 0) + Number(betFiftyAmount || 0);
    if (totalBet > userBalance) {
      alert('Your bets exceed your balance');
      return;
    }

    try {
      const tx = new TransactionBlock();
      let betAmount = totalBet * 10 ** 9;
      let suicoin = tx.splitCoins(tx.gas, [betAmount]);
      tx.transferObjects([suicoin], houseAddress);

      const transaction = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: tx,
      });

      const response = await axios.post(`${serverUrl}/api/flip-coins`, {
        amount: betAmount,
        userAddress: wallet.account.address,
        houseaddress: houseAddress,
        bets: {
          snakeBet,
          eightyChoice: betEighty,
          betEighty: betEightyAmount,
          seventyChoice: betSeventy,
          betSeventy: betSeventyAmount,
          sixtyChoice: betSixty,
          betSixty: betSixtyAmount,
          fiftyChoice: betFifty,
          betFifty: betFiftyAmount,
        },
      });

      const { results, payment, snakeHit } = response.data;
      setResults(results);
      setsnakeHit(snakeHit);

      if (payment > 0) {
        setPopupMessage(`You won ${payment / 10 ** 9} SUI!`);
      } else {
        setPopupMessage('Tough luck, try again');
      }
      setShowPopup(true);

      fetchBalance(walletAddress);

      const transactionResponse = await axios.get(`${serverUrl}/api/transactions`);
      setTransactions(transactionResponse.data);
    } catch (error) {
      console.error('There was an issue getting the transaction info', error);
    }
  };

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const formatTimeAgo = (timestamp) => {
    const diff = Date.now() - timestamp;
    if (diff < 60000) {
      return `${Math.floor(diff / 1000)} seconds ago`;
    } else if (diff < 3600000) {
      return `${Math.floor(diff / 60000)} minutes ago`;
    } else if (diff < 86400000) {
      return `${Math.floor(diff / 3600000)} hours ago`;
    } else {
      return `${Math.floor(diff / 86400000)} days ago`;
    }
  };

  const handleOptionClick = (setOption, value) => {
    setOption(value);
  };

  const getSwitchOptionClass = (betChoice, result, correctOption) => {
    if (result) {
      return betChoice === correctOption
        ? (betChoice === result ? 'switch-correct' : 'switch-incorrect')
        : '';
    }
    return betChoice === correctOption ? 'switch-active' : '';
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">Fourhead Game</h1>
        <div className="header-right">
          <a href="https://x.com/fourheadgame" target="_blank" rel="noopener noreferrer">
            <img src={require('./x-logo.png')} alt="X.com Logo" className="x-logo" />
          </a>
          <button className="instructions-button" onClick={toggleInstructions}>
            How to Play
          </button>
        </div>
      </header>

      {showInstructions && (
        <div className="instructions-popup">
          <h2>How to Play Fourhead Game</h2>
          <div className="instructions-content">
            <div className="instructions-section">
              <button className="section-header" onClick={() => toggleSection('intro')}>
                Introduction to Fourhead {activeSection === 'intro' ? '▲' : '▼'}
              </button>
              {activeSection === 'intro' && (
                <div className="section-content">
                  <p>
                    Fourhead is a fun betting game that revolves around guessing the outcome of coin flips. Each coin has different odds, and you can bet on whether it will land heads or tails. If your guess is correct, you win a reward based on the difficulty of your guess!
                  </p>
                  <p>
                    There are four coins in the game, and each one has different odds. Your objective is to place bets on these coins, predicting if they will land heads or tails, and possibly win big if you guess correctly!
                  </p>
                </div>
              )}
            </div>

            <div className="instructions-section">
              <button className="section-header" onClick={() => toggleSection('coins')}>
                Understanding the Coins {activeSection === 'coins' ? '▲' : '▼'}
              </button>
              {activeSection === 'coins' && (
                <div className="section-content">
                  <p>In Fourhead, there are four coins with different probabilities:</p>
                  <ul>
                    <li><strong>80% Heads Coin:</strong> This coin has an 80% chance of landing heads and a 20% chance of landing tails. The rewards are 1.2x for heads and 3x for tails.</li>
                    <li><strong>70% Heads Coin:</strong> This coin has a 70% chance of landing heads and a 30% chance of landing tails. The rewards are 1.4x for heads and 2.5x for tails.</li>
                    <li><strong>60% Heads Coin:</strong> This coin has a 60% chance of landing heads and a 40% chance of landing tails. The rewards are 1.6x for heads and 2.2x for tails.</li>
                    <li><strong>50% Heads Coin:</strong> This is a fair coin, with a 50% chance of heads and 50% chance of tails. The reward is 2x for heads and 2x for tails.</li>
                  </ul>
                </div>
              )}
            </div>

            <div className="instructions-section">
              <button className="section-header" onClick={() => toggleSection('bets')}>
                How to Place Bets {activeSection === 'bets' ? '▲' : '▼'}
              </button>
              {activeSection === 'bets' && (
                <div className="section-content">
                  <p>Here's how you place a bet in Fourhead:</p>
                  <ul>
                    <li>Select **Heads** or **Tails** for each coin. Each coin has a different probability and reward structure, so choose wisely!</li>
                    <li>Input the amount of SUI you want to bet. Each coin has its own bet field, so you can choose different amounts for each.</li>
                    <li>You can also place a special bet called a **Snake Bet**. If all four coins land on tails, you win 50x your bet!</li>
                  </ul>
                </div>
              )}
            </div>

            <div className="instructions-section">
              <button className="section-header" onClick={() => toggleSection('winning')}>
                How to Win {activeSection === 'winning' ? '▲' : '▼'}
              </button>
              {activeSection === 'winning' && (
                <div className="section-content">
                  <p>Winning in Fourhead is all about guessing correctly! Here’s how rewards work:</p>
                  <ul>
                    <li>If you guess **heads or tails** correctly for any coin, you will receive the reward based on that coin’s odds. For example, if you bet on **tails** for the 80% Heads Coin and it lands tails, you’ll win 3x your bet.</li>
                    <li>If you placed a **Snake Bet** and all coins land on tails, you’ll win 50x your Snake Bet!</li>
                  </ul>
                </div>
              )}
            </div>

            <div className="instructions-section">
              <button className="section-header" onClick={() => toggleSection('strategy')}>
                Expert Tips & Strategy {activeSection === 'strategy' ? '▲' : '▼'}
              </button>
              {activeSection === 'strategy' && (
                <div className="section-content">
                  <p>Here are some tips to help you master Fourhead:</p>
                  <ul>
                    <li>**Balance your risk:** Coins with higher odds for heads have smaller rewards for heads and larger rewards for tails. Balance safe bets with higher-risk ones for bigger rewards.</li>
                    <li>**Test your luck with the Snake Bet:** This is a high-risk, high-reward option. Use it wisely!</li>
                    <li>**Start small:** If you’re new, start with smaller bets until you get the hang of the game.</li>
                  </ul>
                </div>
              )}
            </div>

            <button className="close-instructions-button" onClick={toggleInstructions}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="container">
        {!connected ? (
          <ConnectButton className="connect-button" onError={handleConnectError}>
            Connect Wallet
          </ConnectButton>
        ) : (
          <>
            <div className="wallet-info">
              <ConnectButton />
            </div>
            <div className="bet-section">
              <div className="bet-row">
                <div className="bet-box">
                  <h2>80% Head</h2>
                  <h4>Rewards: Heads=1.2x Tails=3x</h4>
                  <h4> Choose heads or tails below, input amount you want to bet, and flip the coin</h4>
                  <div className="switch-container">
                    <div
                      className={`switch-option switch-heads switch-80-heads ${getSwitchOptionClass(betEighty, results.eighty, 'H')}`}
                      onClick={() => handleOptionClick(setBetEighty, 'H')}
                    >
                      Heads
                    </div>
                    <div
                      className={`switch-option switch-tails switch-20-tails ${getSwitchOptionClass(betEighty, results.eighty, 'T')}`}
                      onClick={() => handleOptionClick(setBetEighty, 'T')}
                    >
                      Tails
                    </div>
                  </div>
                  <input
                    type="number"
                    value={betEightyAmount}
                    min="0"
                    onChange={(e) => setBetEightyAmount(e.target.value)}
                    placeholder="Place Bet"
                    className="bet-input"
                  />
                </div>
                <div className="bet-box">
                  <h2>70% Head</h2>
                  <h4>Rewards: Heads=1.4x Tails=2.5x</h4>
                  <h4> Choose heads or tails below, input amount you want to bet, and flip the coin</h4>
                  <div className="switch-container">
                    <div
                      className={`switch-option switch-heads switch-70-heads ${getSwitchOptionClass(betSeventy, results.seventy, 'H')}`}
                      onClick={() => handleOptionClick(setBetSeventy, 'H')}
                    >
                      Heads
                    </div>
                    <div
                      className={`switch-option switch-tails switch-30-tails ${getSwitchOptionClass(betSeventy, results.seventy, 'T')}`}
                      onClick={() => handleOptionClick(setBetSeventy, 'T')}
                    >
                      Tails
                    </div>
                  </div>
                  <input
                    type="number"
                    value={betSeventyAmount}
                    min="0"
                    onChange={(e) => setBetSeventyAmount(e.target.value)}
                    placeholder="Place Bet"
                    className="bet-input"
                  />
                </div>
                <div className="bet-box">
                  <h2>60% Head</h2>
                  <h4>Rewards: Heads=1.6x Tails=2.2x</h4>
                  <h4> Choose heads or tails below, input amount you want to bet, and flip the coin</h4>
                  <div className="switch-container">
                    <div
                      className={`switch-option switch-heads switch-60-heads ${getSwitchOptionClass(betSixty, results.sixty, 'H')}`}
                      onClick={() => handleOptionClick(setBetSixty, 'H')}
                    >
                      Heads
                    </div>
                    <div
                      className={`switch-option switch-tails switch-40-tails ${getSwitchOptionClass(betSixty, results.sixty, 'T')}`}
                      onClick={() => handleOptionClick(setBetSixty, 'T')}
                    >
                      Tails
                    </div>
                  </div>
                  <input
                    type="number"
                    value={betSixtyAmount}
                    min="0"
                    onChange={(e) => setBetSixtyAmount(e.target.value)}
                    placeholder="Place Bet"
                    className="bet-input"
                  />
                </div>
                <div className="bet-box">
                  <h2>50% Head</h2>
                  <h4>Rewards: Heads=2x Tails=2x</h4>
                  <h4> Choose heads or tails below, input amount you want to bet, and flip the coin</h4>
                  <div className="switch-container">
                    <div
                      className={`switch-option switch-heads switch-50-heads ${getSwitchOptionClass(betFifty, results.fifty, 'H')}`}
                      onClick={() => handleOptionClick(setBetFifty, 'H')}
                    >
                      Heads
                    </div>
                    <div
                      className={`switch-option switch-tails switch-50-tails ${getSwitchOptionClass(betFifty, results.fifty, 'T')}`}
                      onClick={() => handleOptionClick(setBetFifty, 'T')}
                    >
                      Tails
                    </div>
                  </div>
                  <input
                    type="number"
                    value={betFiftyAmount}
                    min="0"
                    onChange={(e) => setBetFiftyAmount(e.target.value)}
                    placeholder="Place Bet"
                    className="bet-input"
                  />
                </div>
                <div className="bet-box">
                  <h2>Snake Bet</h2>
                  <h4>Reward for all tails (The Snake): 50x</h4>
                  <input
                    type="number"
                    value={snakeBet}
                    min="0"
                    onChange={(e) => setsnakeBet(e.target.value)}
                    placeholder="Place Bet"
                    className="bet-input"
                  />
                </div>
              </div>
            </div>
            <div>
              <button onClick={handleFlip} className="flip-button">
                Flip Coins
              </button>
            </div>
            {results && (
              <div className="results-section">
                <p>
                  <strong>Results:</strong>
                </p>
                <p>80% Coin: {results.eighty}</p>
                <p>70% Coin: {results.seventy}</p>
                <p>60% Coin: {results.sixty}</p>
                <p>50% Coin: {results.fifty}</p>
              </div>
            )}
            {showPopup && (
              <div className={`popup ${popupMessage.includes('won') ? 'win' : 'loss'}`}>
                <Confetti className="confetti-wrapper" />
                {popupMessage}
                <button onClick={() => window.location.reload()}>Close</button>
              </div>
            )}
            {snakeHit && snakeBet > 0 && (
              <div className="snake-hit">
                SNAKE HIT! YOU WON THE BIG PRIZE!
                <Confetti className="confetti-wrapper" />
              </div>
            )}
          </>
        )}
      </div>

      <div className="transactions-section">
        <h2>Last 20 Transactions</h2>
        <table className="transactions-table">
          <thead>
            <tr>
              <th>Address</th>
              <th>Bet (SUI)</th>
              <th>Won (SUI)</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.userAddress}</td>
                <td>{transaction.amountBet / 10 ** 9}</td>
                <td style={{ color: transaction.amountWon > 0 ? 'green' : 'red' }}>{transaction.amountWon / 10 ** 9}</td>
                <td>{formatTimeAgo(transaction.timestamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default App;
